<template>
  <div id="404">
    <Navigation light/>
    <div id="notfound">
      <h1>Page not found</h1>
    </div>
    <More/>
    <MobileMore />
  </div>
</template>

<script>
import store from '@/store'

export default {
  components: {
    Navigation: () => import('@/components/nav.vue'),
    More: () => import('@/components/more.vue'),
    MobileMore: () => import('@/components/mobile-more')
  },
  mounted () {
    store.commit('SET_NAV_PAGE', '404'),
    store.commit('SET_MORE_PAGES', [])
    store.commit('SET_MORE_HAS_SCROLL', false)
  }
}
</script>
<style scoped>
div#notfound {
  position: absolute;
  width: 100%;
  height: calc(100% - 66px - 64px);
  top: 66px;
  bottom: 64px;
  overflow-y: scroll;
}
h1 {
  text-align: center;
}
</style>
