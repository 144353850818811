<template>
  <div id="home">
    <Navigation light/>
    <Intro v-if="pages[pageIndex]=='intro'"/>
    <Service v-if="pages[pageIndex]=='service'"/>
    <Portfolio v-if="pages[pageIndex] == 'portfolio'"/>
    <More @next="pageMove"/>
    <MobileMore />
  </div>
</template>

<script>
import store from '@/store'

export default {
  components: {
    Navigation: () => import('@/components/nav.vue'),
    More: () => import('@/components/more.vue'),
    MobileMore: () => import('@/components/mobile-more'),
    Intro: () => import('./intro.vue'),
    Service: () => import('./services.vue'),
    Portfolio: () => import('./portfolio.vue')
  },
  data: () => ({
    pages: [
      'intro',
      'service',
      'portfolio'
    ],
    pageIndex: 0
  }),
  mounted () {
    store.commit('SET_NAV_PAGE', 'home'),
    store.commit('SET_MORE_PAGES', this.pages)
    store.commit('SET_MORE_HAS_SCROLL', true)
  },
  methods: {
    pageMove: function (index) {
      this.pageIndex = index
    }
  }
}
</script>

<style>
div#home {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  overflow: hidden;
}
</style>
