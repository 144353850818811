<template>
  <div id="documentation">
    <Navigation />
    <div class="container-fixed">
      <div class="doc-services-box">
        <div class="doc-services">
          <div>
            <div class="doc-services-title animate__animated animate__fadeInLeft">다국어 현지화 서비스 제공</div>
            <ul class="service-links animate__animated animate__fadeInLeft animate__delay-1s">
              <li v-for="service in services" v-bind:key="service.id"
                :class="service.id == selected_service_id ? ' selected':''">
                <a href="javascript:void(0)" @click="previewService(service.id)">{{service.title}}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="service-preview animate__animated animate__fadeInRight">
        <div id="service-1" :class="selected_service_id == 1?'active':''">
          <div>
            <div class="service-1-panel animate__animated animate__fadeIn animate__delay-1s"
              style="background-image: url('/assets/images/localization/1/현지화 서비스 강점.png'); background-position: bottom right;">
              <div class="service-1-title">전문성</div>
              <div class="service-1-desc">다양한 분야의 풍부한 경험을 가진 전문가들이 최고의 품질의 번역 서비스를 제공합니다.</div>
            </div>
            <div class="service-1-panel animate__animated animate__fadeIn animate__delay-2s"
              style="background-image: url('/assets/images/localization/1/네트워크.png');">
              <div class="service-1-title">네트워크</div>
              <div class="service-1-desc">언어별, 분야별 전문 인적자원으로 최고의 번역 품질을 유지합니다.</div>
            </div>
            <div class="service-1-panel animate__animated animate__fadeIn animate__delay-3s"
              style="background-image: url('/assets/images/localization/1/기술.png'); background-position: top center; padding: 2rem;">
              <div class="service-1-title">기술</div>
              <div class="service-1-desc">다양한 CAT 도구 활용 및 클라우드 번역 환경을 통해 최고의 번역 환경을 유지합니다.</div>
            </div>
            <div class="service-1-panel animate__animated animate__fadeIn animate__delay-4s"
              style="background-image: url('/assets/images/localization/1/품질.png'); background-position: right center; padding: 2rem;">
              <div class="service-1-title">품질</div>
              <div class="service-1-desc">QA 도구를 통한 언어 품질 검사와 자체 체크리스트를 통해 오류를 최소화합니다.</div>
            </div>
          </div>
        </div>
        <div id="service-2" :class="selected_service_id == 2 ? 'active' : ''">
          <div class="service-2-map-mobile animate__animated animate__zoomIn animate__delay-2s"
            style="background-image: url('/assets/images/localization/2/Map.png');"></div>
          <div class="service-2-row">
            <div class="animate__animated animate__fadeInRight">
              <div class="service-2-order" style="color: #1090AF;">01</div>
              <div class="service-2-title" style="color: #1090AF;">아메리카</div>
              <div class="service-2-details">3개 언어 제공<br>Partner Vendor : 3개사<br>F/L 번역사 : 2~5 리소스/언어당</div>
            </div>
            <div class="animate__animated animate__fadeInRight animate__delay-1s">
              <div class="service-2-order" style="color: #6596D0;">02</div>
              <div class="service-2-title" style="color: #6596D0;">유럽</div>
              <div class="service-2-details">36개 언어 제공<br>Partner Vendor : 5개사<br>F/L 번역사 : 2~3 리소스/언어당</div>
            </div>
            <div class="animate__animated animate__fadeInRight animate__delay-2s">
              <div class="service-2-order" style="color: #0071B7;">03</div>
              <div class="service-2-title" style="color: #0071B7;">중동</div>
              <div class="service-2-details">9개 언어 제공<br>Partner Vendor : 2개사<br>F/L 번역사 : 2~3 리소스/언어당</div>
            </div>
          </div>
          <div class="service-2-map animate__animated animate__zoomIn animate__delay-2s"
            style="background-image: url('/assets/images/localization/2/Map.png');"></div>
          <div class="service-2-row">
            <div class="animate__animated animate__fadeInRight animate__delay-3s">
              <div class="service-2-order" style="color: #008ED6;">04</div>
              <div class="service-2-title" style="color: #008ED6;">아시아</div>
              <div class="service-2-details">17개 언어 제공<br>Partner Vendor : 3개사<br>F/L 번역사 : 2~5 리소스/언어당</div>
            </div>
            <div class="animate__animated animate__fadeInRight animate__delay-4s">
              <div class="service-2-order" style="color: #2EBAD6;">05</div>
              <div class="service-2-title" style="color: #2EBAD6;">아프리카</div>
              <div class="service-2-details">24개 언어 제공<br>Partner Vendor : 3개사<br>F/L 번역사 : 2~5 리소스/언어당</div>
            </div>
            <div class="animate__animated animate__fadeInRight animate__delay-5s">
              <div class="service-2-order" style="color: #005698;">06</div>
              <div class="service-2-title" style="color: #005698;">오세아니아</div>
              <div class="service-2-details">3개 언어 제공<br>Partner Vendor : 3개사<br>F/L 번역사 : 2~5 리소스/언어당</div>
            </div>
          </div>
        </div>
        <div id="service-3" :class="selected_service_id == 3 ? 'active' : ''">
          <div class="service-3-row">
            <div class="service-3-panel animate__animated animate__fadeIn animate__delay-1s"
              style="background-color: #65A8CE; background-image: url('/assets/images/localization/3/기술문서 Icon.png');">
              <div class="service-3-title">비즈니스/기술문서</div>
              <div class="service-3-desc">장비 매뉴얼 / 사용자 설명서<br>기술논문 / 특허자료<br>교육자료 / 리포트</div>
            </div>
            <div class="service-3-panel animate__animated animate__fadeIn animate__delay-2s"
              style="background-color: #6EC4F5; background-image: url('/assets/images/localization/3/소프트웨어 Icon.png');">
              <div class="service-3-title">소프트웨어</div>
              <div class="service-3-desc">소프트웨어 UI 및 UX<br>소프트웨어 도움말, 설명서<br>Online / Mobile Web<br>Mobile Application
              </div>
            </div>
          </div>
          <div class="service-3-row">
            <div class="service-3-panel animate__animated animate__fadeIn animate__delay-3s"
              style="background-color: #82C0CB; background-image: url('/assets/images/localization/3/기업 홍보 콘텐츠 Icon.png');">
              <div class="service-3-title">기업 홍보 콘텐츠</div>
              <div class="service-3-desc">해외 언론 보도자료<br>이벤트 캠페인<br>기업 홍보자료</div>
            </div>
            <div class="service-3-panel animate__animated animate__fadeIn animate__delay-4s"
              style="background-color: #F8C579; background-image: url('/assets/images/localization/3/전자상거래 Icon.png');">
              <div class="service-3-title">멀티미디어/전자상거래</div>
              <div class="service-3-desc">영상 콘텐츠 자막<br>쇼핑몰<br>제품 상세 페이지</div>
            </div>
          </div>
        </div>
        <div id="service-4" :class="selected_service_id == 4 ? 'active' : ''">
          <div class="service-4-row animate__animated animate__fadeInUp">
            <div class="service-4-icon">
              <img src="/assets/images/localization/4/wired-outline-12-layers.gif" alt="">
            </div>
            <div class="service-4-order">01</div>
            <div class="service-4-details">
              <div class="service-4-title">01 의뢰 및 파일 검토</div>
              <div class="service-4-desc">분야, 파일 포맷, 대상 언어 및 작업 범위 확인</div>
            </div>
          </div>
          <div class="service-4-row service-4-row-reverse animate__animated animate__fadeInUp animate__delay-1s">
            <div class="service-4-icon">
              <img src="/assets/images/localization/4/wired-outline-19-magnifier-zoom-search.gif" alt="">
            </div>
            <div class="service-4-order">02</div>
            <div class="service-4-details">
              <div class="service-4-title">02 번역 착수 준비</div>
              <div class="service-4-desc">세부 일정 수립<br>발주 파일 준비<br>언어별/분야별 전문 번역사 섭외
              </div>
            </div>
          </div>
          <div class="service-4-row animate__animated animate__fadeInUp animate__delay-2s">
            <div class="service-4-icon">
              <img src="/assets/images/localization/4/wired-outline-220-arrow-9.gif" alt="">
            </div>
            <div class="service-4-order">03</div>
            <div class="service-4-details">
              <div class="service-4-title">03 번역 진행</div>
              <div class="service-4-desc">스타일가이드 및 용어집 등 참조 문서 사전 전달 및 숙지 후 작업 진행 쿼리 시트/Q&A 진행<br>일정에 따른 진행상황 확인/작업자 관리
              </div>
            </div>
          </div>
          <div class="service-4-row service-4-row-reverse animate__animated animate__fadeInUp animate__delay-3s">
            <div class="service-4-icon">
              <img src="/assets/images/localization/4/wired-outline-112-book.gif" alt="">
            </div>
            <div class="service-4-order">04</div>
            <div class="service-4-details">
              <div class="service-4-title">04 리뷰 작업</div>
              <div class="service-4-desc">번역 언어 및 지역/국가에 따른 용어, 어조, 문법 등 오류 검토 및 수정 용어, 스타일, 문법 등 리뷰 진행</div>
            </div>
          </div>
          <div class="service-4-row animate__animated animate__fadeInUp animate__delay-4s">
            <div class="service-4-icon">
              <img src="/assets/images/localization/4/wired-document-edit.gif" alt="">
            </div>
            <div class="service-4-order">05</div>
            <div class="service-4-details">
              <div class="service-4-title">05 DTP 문서 편집</div>
              <div class="service-4-desc">파일 변환 후 문서 및 이미지 편집<br>번역 언어 및 지역/국가의 특성에 따른 편집 실시</div>
            </div>
          </div>
          <div class="service-4-row service-4-row-reverse animate__animated animate__fadeInUp animate__delay-5s">
            <div class="service-4-icon">
              <img src="/assets/images/localization/4/wired-outline-259-share-arrow.gif" alt="">
            </div>
            <div class="service-4-order">06</div>
            <div class="service-4-details">
              <div class="service-4-title">06 QA 및 납품</div>
              <div class="service-4-desc">언어/디자인 QA 실시<br>고객 납품 및 피드백 검토/반영</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BgTextree />
  </div>
</template>

<script>
import store from '@/store'

export default {
  components: {
    Navigation: () => import('@/components/nav.vue'),
    BgTextree: () => import('@/components/bg-textree.vue')
  },
  data: () => ({
    services: [
      {
        id: 1,
        title: '현지화 서비스 강점'
      },
      {
        id: 2,
        title: '글로벌 서비스 언어'
      },
      {
        id: 3,
        title: '서비스 제공 분야'
      },
      {
        id: 4,
        title: '현지화 프로세스'
      }
    ],
    selected_service_id: {}
  }),
  mounted() {
    store.commit('SET_NAV_PAGE', 'localization')
    store.commit('SET_NAV_THEME', 'dark')
    this.previewService(1);
  },
  methods: {
    previewService: function (id) {
      this.selected_service_id = id
      setTimeout(function () {
        document.getElementById('service-' + id).scrollIntoView();
      }, 100)
    }
  }
}
</script>
<style scoped>

.service-preview > div {
  display: none;
}
.service-preview>div.active {
  display: block;
}

#service-1 {
  margin-left: 2rem;
  margin-top: 4rem;
  height: calc(100% - 3rem);
  font-family: 'Roboto';
}
#service-1 > div {
  height: 100%;
  display: grid;
  grid-template-columns: 45% 45%;
  grid-template-rows: 50% 50%;
}
#service-1 .service-1-panel {
  background-size: cover;
  margin: .5rem;
  background-repeat: no-repeat;
  background-position: bottom right;
  border-radius: 1rem;
  padding: 2rem 2rem;
  padding-right: 5rem;
  display: flex;
  flex-direction: column;
}
#service-1 .service-1-title {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  margin-bottom: 1rem;
}
#service-1 .service-1-desc {
  font-size: 1.25rem;
  color: white;
}
#service-1 div .service-1-panel:nth-child(3),
#service-1 div .service-1-panel:nth-child(4) {
  justify-content: flex-end;
}

#service-2 {
  margin-top: 4rem;
  font-family: 'Roboto';
}
#service-2 .service-2-row {
  display: flex;
  justify-content: space-around;
}
#service-2 .service-2-map {
  background-size: contain;
  background-position: center;
  margin: 2rem 0;
  height: 20rem;
  background-repeat: no-repeat;
}
.service-2-map-mobile {
  display: none;
}
#service-2 .service-2-row > div {
  position: relative;
}
#service-2 .service-2-order {
  position: absolute;
  margin-left: -4rem;
  margin-top: -.25rem;
  font-size: 2.5rem;
  font-weight: 700;
  font-family: 'Montserrat';
  color: #1090AF;
}
#service-2 .service-2-title {
  color: #1090AF;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: .5rem;
}
#service-2 .service-2-details {
  color: #707070;
  font-size: 1.15rem;
}

#service-3 {
  margin-left: 2rem;
  margin-top: 4rem;
  height: calc(100% - 5rem);
  font-family: 'Roboto';
}
#service-3 .service-3-row {
  height: 50%;
  display: grid;
  grid-template-columns: 45% 45%;
}
#service-3 .service-3-row:nth-child(2) {
  grid-template-columns: 40% 50%;
}
#service-3 .service-3-panel {
  background-size: 38%;
  margin: .5rem;
  background-repeat: no-repeat;
  background-position: 90% 90%;
  border-radius: 1rem;
  padding: 3rem 2rem;
}
#service-3 .service-3-title {
  font-size: 2rem;
  font-weight: 600;
  color: white;
  margin-bottom: 1.25rem;
}
#service-3 .service-3-desc {
  font-size: 1.25rem;
  color: white;
}

#service-4 {
  margin-top: 2rem;
  font-family: 'Roboto';
  position: relative;
}
#service-4 .service-4-row {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
}
#service-4 .service-4-row-reverse {
  flex-direction: row-reverse;
}
#service-4 .service-4-order {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-width: 3.375rem;
  min-height: 3.375rem;
  background: #008ED6;
  text-align: center;
  color: white;
  font-family: 'Montserrat';
  font-weight: 700;
  font-size: 1.4rem;
  margin: 0 2rem;
}
#service-4 .service-4-icon {
  width: 22rem;
  text-align: right;
}
#service-4 .service-4-row-reverse .service-4-icon {
  text-align: left;
}
#service-4 .service-4-details {
  width: 22rem;
}
#service-4 .service-4-icon > img {
  width: 5rem;
  height: 5rem;
}
#service-4 .service-4-title {
  font-weight: 600;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}
#service-4 .service-4-row-reverse .service-4-details {
  text-align: right;
}
#service-4 .service-4-desc {
  color: #959595;
}
#service-4 .service-4-line {
  left: 50%;
  position: absolute;
  margin-top: 1rem;
  height: calc(100% - 5rem);
  width: 1px;
  background: #D9D9D9;
}

.doc-services-box {
  max-width: 1600px;
  margin: 15% auto;
  height: 50%;
  z-index: 144;
  position: relative;
}
.doc-services {
  margin-top: -5em;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.doc-services > div {
  width: calc(100% - 100px - 48px);
  padding-left: 100px;
  padding-right: 48px;
}

.doc-services-title {
  color: #008ED6;
  padding: 0 12px;
  font-family: "Montserrat";
  font-size: 22px;
  line-height: 70px;
  font-weight: bold;
}

ul.service-links {
  list-style-type: none;
  padding: 0;
}
ul.service-links li a {
  color: #CFCFCF;
  padding: 6px 12px;
  font-family: "Roboto";
  font-size: 45px;
  line-height: 70px;
  text-decoration: none;
  position: relative;
}
ul.service-links li.selected a {
  color: #2E2E2E;
}
ul.service-links li.selected a::before {
  position: absolute;
  border-top: 1px solid #707070;
  content: " ";
  width: 75px;
  top: 50%;
  left: -100px;
}

.service-preview {
  position: absolute;
  right: 2rem;
  bottom: 1rem;
  width: calc(100% - 40%);
  height: calc(100% - 100px);
  background-size: cover;
  background-position: center;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.service-body {
  position: absolute;
  left: 0;
  bottom: 0;
  width: calc(75% - 48px);
  background: #008ED6;
  color: white;
  padding: 24px;
}

.service-title {
  font-family: "Nanum Gothic";
  font-size: 40px;
  font-weight: bold;
  line-height: 30px;
  padding: 24px;
}
.service-desc {
  font-family: "Nanum Gothic";
  font-size: 24px;
  font-weight: normal;
  line-height: 45px;
  padding: 0 24px;
  width: calc(75% - 12px);
}

.service-link a {
  display: inline-block;
  color: white;
  background: #F1B500;
  padding: 12px 24px;
  border-radius: 31px;
  text-decoration: none;
  z-index: 1024;
  margin: 12px 0 0;
}

@media(max-width: 1440px) {
  #service-1 > div {
    grid-template-columns: 50% 50%;
  }
  #service-3 .service-3-row {
    grid-template-columns: 50% 50%;
  }
  #service-3 .service-3-row:nth-child(2) {
    grid-template-columns: 45% 55%;
  }
  #service-2, #service-4 {
    margin-top: -3rem;
  }
  .doc-services-box {
    margin-top: calc(15% + 66px);
  }
  ul.service-links li a {
    font-size: 36px;
  }
  .service-preview {
    width: calc(100% - 36%);
  }
  .service-body {
    width: calc(85% - 48px);
  }
  .service-title {
    font-size: 36px;
  }
  .service-desc {
    line-height: 36px;
  }
}

@media(max-width: 1024px) {
  #service-1 .service-1-panel {
    padding: 1.5rem;
  }
  #service-1 .service-1-title {
    font-size: 1.5rem;
  }
  #service-1 .service-1-desc {
    font-size: 1rem;
  }
  #service-2 {
    margin-top: 0;
  }
  #service-2 .service-2-map {
    margin: 0;
  }
  #service-2 .service-2-order {
    font-size: 2rem;
    margin-left: -3rem;
  }
  #service-2 .service-2-title {
    font-size: 1.25rem;
  }
  #service-2 .service-2-details {
    font-size: .85rem;
  }
  #service-2 .service-2-map {
    height: 16rem;
    margin: 1rem 0;
  }
  #service-3 {
    margin-top: 0;
    height: 100%;
  }
  #service-3 .service-3-panel {
    background-size: 45%;
    padding: 1.5rem;
  }
  #service-3 .service-3-title {
    font-size: 1.35rem;
  }
  #service-3 .service-3-desc {
    font-size: 1.15rem;
  }
  #service-4 .service-4-order {
    margin: 0 1rem;
    min-width: 3rem;
    min-height: 3rem;
    font-size: 1.25rem;
  }
  #service-4 .service-4-icon > img {
    width: 3.5rem;
    height: 3.5rem;
  }
  #service-4 .service-4-title {
    font-size: 1.15rem;
    margin-bottom: .5rem;
  }
  #service-4 .service-4-desc {
    font-size: .9rem;
  }
  .doc-services-box {
    margin-top: calc(25% + 66px);
  }
  .doc-services > div {
    padding-left: 1rem;
  }
  ul.service-links li.selected a::before {
    left: -80px;
  }
  ul.service-links li a {
    font-size: 24px;
    line-height: 48px;
  }
  .service-preview {
    width: calc(100% - 36%);
    z-index: 199;
  }
  .service-body {
    width: calc(100% - 48px);
  }
  .service-title {
    padding: 12px 0;
    font-size: 24px;
  }
  .service-desc {
    font-size: 16px;
    padding: 0;
    line-height: 24px;
  }
  .service-link a {
    padding: 12px 24px;
  }
}

@media(max-width: 768px) {
  .nav {
    background: white;
  }
  .service-preview {
    position: relative;
    width: calc(100% - 2rem);
    height: auto;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    padding-top: 2rem;
    padding-bottom: 1rem;
  }
  .doc-services-box {
    margin-top: calc(3rem + 66px);
    margin-left: calc(100px);
    margin-bottom: 2rem;
    height: auto;
  }
  .doc-services {
    width: auto;
    margin-top: 0;
  }
  .doc-services > div {
    width: auto;
  }
  #service-1, #service-3 {
    margin: 0;
  }
  #service-1 .service-1-panel {
    min-height: 18rem;
  }
  .service-2-map {
    display: none;
  }
  .service-2-map-mobile {
    display: block;
    width: 100%;
    height: 16rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  #service-2 .service-2-row {
    flex-direction: column;
  }
  #service-2 .service-2-row > div {
    margin-left: 8rem;
    margin-bottom: 2rem;
  }
  #service-2 .service-2-order {
    font-size: 3rem;
    margin-left: -5rem;
  }
  #service-2 .service-2-title {
    font-size: 2rem;
  }
  #service-2 .service-2-details {
    font-size: 1.25rem;
  }
  #service-3 .service-3-panel {
    min-height: 20rem;
  }
  #service-2, #service-4 {
    margin: 0;
  }
  #service-4 .service-4-icon > img {
    width: 5rem;
    height: 5rem;
  }
  #service-4 .service-4-title {
    font-size: 1.75rem;
  }
  #service-4 .service-4-desc {
    font-size: 1.25rem;
  }
}

@media(max-width: 576px) {
  .doc-services-box {
    width: auto;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .doc-services {
    margin-top: 25%;
  }
  .doc-services > div {
    padding-left: 24px;
    width: calc(100% - 48px);
  }
  .doc-services-title {
    font-size: 18px;
    padding: 0;
    line-height: 36px;
  }
  ul.service-links li a {
    font-size: 16px;
    padding: 0;
    line-height: 36px;
  }
  .service-body {
    width: calc(100% - 24px);
    height: calc(100% - 24px);
    padding: 12px;
    background: rgba(0, 0, 0, .5);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .service-title {
    font-size: 18px;
    line-height: 24px;
  }
  .service-desc {
    font-size: 12px;
    width: 100%;
    line-height: 18px;
  }
  .service-link {
    padding: 12px 0;
  }
  .service-link a {
    background: transparent;
    border: 1px solid white;
    border-radius: 0;
    display: block;
    padding: 6px;
  }
  .service-preview {
    padding-top: 0;
  }
  #service-1 > div {
    display: block;
  }
  .service-2-map-mobile {
    height: 12rem;
  }
  #service-2 .service-2-row > div {
    margin-left: 3rem;
  }
  #service-2 .service-2-order {
    font-size: 2rem;
    margin-left: -3rem;
    margin-top: 0;
  }
  #service-2 .service-2-title {
    font-size: 1.5rem;
  }
  #service-2 .service-2-details {
    font-size: 1rem;
  }
  #service-3 .service-3-row {
    display: block;
  }
  #service-4 .service-4-icon {
    width: auto;
  }
  #service-4 .service-4-title {
    font-size: 1.25rem;
  }
  #service-4 .service-4-desc {
    font-size: .9rem;
  }
}

@media(max-height: 680px) {
  .doc-services-box {
    margin-top: calc(25% + 66px);
  }
}
</style>

<style>
#plusfriend-chat-button {
  bottom: 76px;
}
#plusfriend-chat-button a {
  right: -128px;
}

@media(max-width: 1440px) {
  #plusfriend-chat-button a {
    right: 24px;
  }
}

@media(max-width: 1024px) {
  #plusfriend-chat-button {
    bottom: 24px;
  }
}

@media(max-width: 576px) {
  #plusfriend-chat-button {
    bottom: 24px;
  }
}
</style>
